<template>
  <div id="PaySuccess">
    <div class="main">
      <div>
        <div class="head">
          <van-nav-bar
            title="交易未能发起"
            style="background-color: rgba(255,255,255,0);"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div class="box-shadow">
          <img src="../../assets/images/bg-img.jpg">
          <div class="bottom-part">
            <div class="title">当前时段无法交易..</div>
            <div class="des">抱歉，系统在23：00 - 6:00无法</div>
            <van-button class="btn" type="info">返回</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaySuccess',
  data() {
    return {
    }
  }
}
</script>
<style scoped lang="scss">
#PaySuccess {
  .head {
    height: 84px;
    width: 100%;
    background: linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  background-color: #fff;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .box-shadow{
    width: 100%;
    box-shadow:0px 2px 4px 0px rgba(14,83,181,0.1);
    border-radius:10px;
    img{
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .bottom-part{
      padding: 20px;
      .title{
        font-size:18px;
      }
      .des{
        font-size: 14px;
        color:rgba(116,116,116,1);
        margin: 11px 0 31px;
      }
      .btn{
        width: 100%;
        border-radius: 4px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #ffffff;
}
::v-deep .van-nav-bar__title {
  color: #ffffff;
}
::v-deep .van-nav-bar__text {
  color: #ffffff;
}
[class*="van-hairline"]::after {
  border: none;
}
::v-deep .van-nav-bar {
  height: 80px;
}
</style>
